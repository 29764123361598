import Multiview from './Multiview';
import { useEffect, useRef, useState } from 'react';

export const getURL = (envVar: string | undefined) => {
  return `${envVar || ''}`.startsWith('http') ? envVar : new URL(envVar || '', window.origin).href;
};
export const PLATFORM_SERVER_URL = getURL(process.env.REACT_APP_PLATFORM_SERVER_URL);
export const PLATFORM_CONTROLLER_URL = getURL(process.env.REACT_APP_PLATFORM_CONTROLLER_URL);
export const PLATFORM_DISPLAY_URL = getURL(process.env.REACT_APP_PLATFORM_DISPLAY_URL);
export const GAME_START_SERVER_URL = getURL(process.env.REACT_APP_GAME_START_SERVER_URL);
export const GAME_START_CONTROLLER_URL = getURL(process.env.REACT_APP_GAME_START_CONTROLLER_URL);
export const GAME_START_DISPLAY_URL = getURL(process.env.REACT_APP_GAME_START_DISPLAY_URL);

const urlParams = new URLSearchParams(window.location.search);
export const serverURL = `${urlParams.get('serverURL') || GAME_START_SERVER_URL || ''}`.replace(/\/+$/, '');
const initialMatchID = urlParams.get('matchID') || process.env.REACT_APP_MATCH_ID;

const CONTROLLER_BASE_URL = GAME_START_CONTROLLER_URL || '';
const DISPLAY_BASE_URL = GAME_START_DISPLAY_URL || '';

export const getMatchData = async (matchID: string) => {
  try {
    const res = await fetch(`${serverURL}/games/ticket-to-ride/${matchID}/`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    return await res.json();
  } catch (err) {
    console.error(`Could not getMatchData\n`, err);
  }
};

// Put player names and avatars here
const PLAYERS = [
  {
    name: 'Ohad',
    avatarUrl: 'https://i.pravatar.cc/150?img=3',
  },
  {
    name: 'Lior',
    avatarUrl: 'https://i.pravatar.cc/150?img=4',
  },
  {
    name: 'Shachar',
    avatarUrl: 'https://i.pravatar.cc/150?img=5',
  },
  {
    name: 'Uriel',
    avatarUrl: 'https://i.pravatar.cc/150?img=6',
  },
  {
    name: 'Ella',
    avatarUrl: 'https://i.pravatar.cc/150?img=7',
  },
  {
    name: 'Lahav',
    avatarUrl: 'https://i.pravatar.cc/150?img=8',
  },
];

const calcCreateMatchData = (numHumanPlayers: number) => {
  const players = PLAYERS.map((player, i) => ({
    ...player,
    isBot: i >= numHumanPlayers,
  }));

  return {
    numPlayers: PLAYERS.length,
    setupData: {
      players,
    },
  };
};

export const createMatch = async (createMatchData) => {
  try {
    const res = await fetch(`${serverURL}/games/ticket-to-ride/create`, {
      method: 'POST',
      body: JSON.stringify(createMatchData),
      headers: { 'Content-Type': 'application/json' },
    });
    return await res.json();
  } catch (err) {
    console.error(err);
  }
};

export const displayBaseURL = urlParams.get('displayBaseURL') || DISPLAY_BASE_URL;
export const controllerBaseURL = urlParams.get('controllerBaseURL') || CONTROLLER_BASE_URL;

interface Player {
  id: string;
  name?: string;

  [key: string]: any;
}

const controllerForPlayer = (controllerBaseURL: string, serverURL: string, matchID: string, player: Player) => {
  return {
    id: player.id,
    name: player.name,
    url: `${controllerBaseURL}?playerID=${player.id}&matchID=${matchID}&serverURL=${serverURL}&hidePanel`,
  };
};

const App = () => {
  const [controllers, setControllers] = useState([{ id: '0' }, { id: '1' }]);
  const numPlayersRef = useRef<HTMLSelectElement>();
  const [matchID, setMatchID] = useState(initialMatchID);

  useEffect(() => {
    let mounted = true;

    if (matchID) {
      try {
        getMatchData(matchID).then((data) => {
          if (mounted) {
            setControllers(
              data.players.map((player: any) => {
                return controllerForPlayer(controllerBaseURL, serverURL, matchID, player);
              })
            );
          }
        });
      } catch (err) {
        console.error(`Could not setControllers after getMatchData\n`, err);
      }
    }
    return () => {
      mounted = false;
    };
  }, [matchID]);

  if (matchID) {
    console.log({ matchID });
    window.location.href = `${displayBaseURL}?matchID=${matchID}&serverURL=${serverURL}`;
    return (
      <Multiview displayURL={`${displayBaseURL}?matchID=${matchID}&serverURL=${serverURL}`} controllers={controllers} />
    );
  } else {
    return (
      <div>
        <select defaultValue={'1'} ref={numPlayersRef} name="numPlayers">
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
        </select>
        <button
          onClick={() => {
            const numHumanPlayers = parseInt(numPlayersRef.current && numPlayersRef.current.value) || 3;
            const createMatchData = calcCreateMatchData(numHumanPlayers);
            createMatch(createMatchData).then((result) => {
              const createdMatchID = result.matchID;
              if (createdMatchID) {
                setMatchID(createdMatchID);
              } else {
                console.error('No matchID in createMatch() result');
              }
            });
          }}
        >
          Create Match
        </button>
      </div>
    );
  }
};

export default App;
