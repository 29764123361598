import styles from './Multiview.module.css';
import styled from 'styled-components';

const Controller = styled.div`
  margin: 10px;
  z-index: 1;
  position: absolute;
  left: calc(
    ${(props) => props.controllerID} * (var(--controller-width) * var(--controller-scale) + var(--controller-spacing)) -
      var(--controller-left-offset)
  );
  top: calc(var(--display-bottom) - var(--controller-top-diff));
  width: 375px;
  height: 812px;
  transform: scale(var(--controller-scale));
`;

const Multiview = ({ displayURL, controllers }) => {
  return (
    <div className={styles.container}>
      <iframe
        className={styles.display}
        title="Display"
        scrolling="no"
        src={displayURL}
        allow="screen-wake-lock"
      ></iframe>
      {controllers.map((controller) => (
        <Controller controllerID={controller.id} key={controller.id}>
          <a href={controller.url} target="_blank" rel="noreferrer">
            Open New Tab
          </a>
          <iframe
            style={{ width: '100%', height: '100%' }}
            title={controller.name || `Controller ${controller.id}`}
            src={controller.url}
            allow="screen-wake-lock"
          ></iframe>
        </Controller>
      ))}
    </div>
  );
};

export default Multiview;
